import React from 'react'

const PhotoAR = () => {
    return (
        <div className="h-full w-full">
            <model-viewer
                alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
                src={process.env.PUBLIC_URL + "/models/Bichofue/Bichofue.glb"}
                ar ar-modes="webxr scene-viewer"
                poster={process.env.PUBLIC_URL + "/images/aves/op_bicho.png"}
                shadow-intensity="1"
                camera-controls
                autoplay
                touch-action="pan-y"
                className="h-screen w-screen" style={{width:"100vw", height:"100vh"}}>
                <button
                    slot='ar-button'
                    id='ar-button'
                    className='absolute bottom-0 left-1/2'>
                    View in your space
                </button>
                {/* <a-scene embeded xr-mode-ui="enabled: false"
                device-orientation-permission-ui="enabled: false">
                    <a-box position="0 2 0" rotation="0 45 45" scale="2 4 2">
                        <a-sphere position="1 0 3"></a-sphere>
                    </a-box>
                </a-scene> */}
            </model-viewer>
        </div>

    )
}

export default PhotoAR