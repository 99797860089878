import React from 'react';

import { anim_spawn_ar_container } from '../AfichesAR';
import ARCloud from './ARCloud';

const Afiche2 = () => {
    return (
        <a-entity id="contenedor-0" position="0 0 0" rotation="0 0 0" animation__spawn={anim_spawn_ar_container}>
            {/* <a-plane
                id="image-1"
                src={process.env.PUBLIC_URL + "/ar_image_targets/Afiches_page_0001.jpg"}
                width="1" height="0.5641"
            ></a-plane>
            <a-sphere position="0 0 0" radius="0.1" color="#EF2D5E"></a-sphere> */}



            <a-gltf-model
                position="0.071 -0.011 0.092" 
                rotation="0 -17.770 0" 
                scale="0.139 0.139 0.139"
                src={process.env.PUBLIC_URL + "/models/Montanerito/Montanerito.glb"}
                visible="true"
                animation-mixer="clip: idle_Paisa; crossFadeDuration:0.2"
                custom-glb-material={`
                    map:${process.env.PUBLIC_URL + "/models/Montanerito/base_color_texture.png"};
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    transparent: false;
                    opacity: 1;
                `}
            ></a-gltf-model>

            <a-gltf-model
                position="-0.033 0.029 0.035" 
                rotation="0 0 0" 
                scale="0.095 0.095 0.095"
                src={process.env.PUBLIC_URL + "/models/BucoBobito/BucoBobito.glb"}
                visible="true"
                animation-mixer="clip: root|root|root|Buco_Bobito_Volar; crossFadeDuration:0.2"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    transparent: false;
                    opacity: 1;
                `}
            ></a-gltf-model>

            <a-gltf-model
                position="-0.302 -0.153 0.601" 
                rotation="0 -96.270 -4.768" 
                scale="13 13 13"
                src={process.env.PUBLIC_URL + "/models/Wood.glb"}
                visible="true"
                hide-nodes="nodes: Group1, Group2, Group4, Group5, Group6, Group7, Group8, Group9, Group10"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    opacity: 1;
                `}
            >
            </a-gltf-model>

            <ARCloud 
                position="-0.350 -0.190 0.038"
                rotation="0 0 0" 
                scale="0.2 0.2 0.2"
                type={0}
            />

            <ARCloud 
                position="-0.181 -0.138 0.037" 
                rotation="0 0 0" 
                scale="0.15 0.15 0.15"
                type={1}
            />

            <ARCloud 
                position="0.335 -0.114 0.086" 
                rotation="0 0 0" 
                scale="0.18 0.18 0.18"
                type={1}
            />

            <ARCloud 
                position="0.245 0.080 0.036" 
                rotation="0 0 0" 
                scale="0.13 0.13 0.13"
                type={0}
            />

            <ARCloud 
                position="-0.328 0.104 0.037" 
                rotation="0 0 0" 
                scale="0.12 0.12 0.12"
                type={1}
            />

        </a-entity>
    )
}

export default Afiche2