import React from 'react'
import { motion } from 'framer-motion'
const logos = [
    process.env.PUBLIC_URL + '/images/cvc/logo-nuestro-oxigeno.jpeg',
    process.env.PUBLIC_URL + '/images/cvc/logo-dacnis.jpeg',
    process.env.PUBLIC_URL + '/images/cvc/logo-cvc.png',
    process.env.PUBLIC_URL + '/images/cvc/logo-gobernacion.png',
    process.env.PUBLIC_URL + '/images/cvc/logo-pau.png',
    process.env.PUBLIC_URL + '/images/cvc/logo-calidris_2.png',
    process.env.PUBLIC_URL + '/images/cvc/logo-plan-dep-aves.png',
    process.env.PUBLIC_URL + '/images/cvc/logo-dagma.png',
    process.env.PUBLIC_URL + '/images/cvc/logo-concejo-cali.png',
    process.env.PUBLIC_URL + '/images/cvc/logo-cali.png'
]

const Logo = ({ image }) => {
    return (
        <div className="px-2 py-3 h-20 w-full">
            <div className="bg-white px-1 py-1 flex items-center justify-center rounded-lg h-full w-full">
                <img loading="lazy"
                    src={image}
                    className=" object-contain object-center h-full w-full overflow-hidden self-center" alt="logo"
                />
            </div>

        </div>

    )
}

const HeaderCVC = () => {
    return (
        <header className="fixed top-0 body-font inset-x-0 z-50 bg-secondary/30 backdrop-blur-sm hover:bg-blend-soft-light">

            <div className="container mx-auto flex flex-wrap md:p-1 md:px-8 p-1 flex-row justify-center items-center gap-4">

                {/* {logos.map((logo, i) => {
                    return (
                        <img key={i} src={logo} className='h-12' />
                    )
                })
                } */}

                <div className="relative w-full overflow-hidden
                    [mask-image:_linear-gradient(to_right,transparent_0,_black_80px,_black_calc(100%-50px),transparent_100%)]">
                    {/* Wrapping div for seamless looping */}
                    <motion.div
                        className="flex"
                        animate={{
                            x: ['-320%', '50%'],
                            transition: {
                                ease: 'linear',
                                duration: 24,
                                repeat: Infinity,
                            }
                        }}
                    >
                        {/* Render duplicated slides */}
                        {logos.map((slide, index) => (
                            <div key={index} className="flex flex-shrink-0 justify-center align-center" style={{ width: `${320 / logos.length}%` }}>
                                {/* <img src={slide} className='h-12 object-contain' /> */}
                                <Logo image={slide} />
                            </div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </header>
    )
}

export default HeaderCVC