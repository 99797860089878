import React from 'react';

import { anim_spawn_ar_container } from '../AfichesAR';
import ARCloud from './ARCloud';

const Afiche3 = () => {
    return (
        <a-entity id="contenedor-0" position="0 0 0" rotation="0 0 0" animation__spawn={anim_spawn_ar_container}>
            {/* <a-plane
                id="image-1"
                src={process.env.PUBLIC_URL + "/ar_image_targets/Afiches_page_0001.jpg"}
                width="1" height="0.5641"
            ></a-plane>
            <a-sphere position="0 0 0" radius="0.1" color="#EF2D5E"></a-sphere> */}



            <a-gltf-model
                position="0.091 -0.085 0.015" 
                rotation="0 28.434 0" 
                scale="0.073 0.073 0.073"
                src={process.env.PUBLIC_URL + "/models/ColibriColiazul/Colibri_2.glb"}
                visible="true"
                animation-mixer="clip: rig.001|Idle; crossFadeDuration:0.2"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0.2;
                    roughness: 0.8;
                    transparent: false;
                    opacity: 1;
                `}
            ></a-gltf-model>

            <a-gltf-model
                position="-0.186 -0.077 0.116" 
                rotation="0 87.600 0" 
                scale="0.07 0.07 0.07"
                src={process.env.PUBLIC_URL + "/models/ColibriColiraqueta/ColibriColiraqueta.glb"}
                visible="true"
                animation-mixer="clip: rig.001|Take_off; crossFadeDuration:0.2"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    transparent: false;
                    opacity: 1;
                `}
            ></a-gltf-model>

            <a-gltf-model
                position="-0.034 -0.208 -0.405" 
                rotation="-0.825 112.988 -4.697" 
                scale="10 10 10"
                src={process.env.PUBLIC_URL + "/models/Wood.glb"}
                visible="true"
                hide-nodes="nodes: Group1, Group2, Group4, Group5, Group6, Group7, Group8, Group9, Group10"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    opacity: 1;
                `}
            >
            </a-gltf-model>

            <ARCloud 
                position="-0.350 0.118 0.038"
                rotation="0 0 0" 
                scale="0.2 0.2 0.2"
                type={0}
            />

            <ARCloud 
                position="-0.181 -0.203 0.037" 
                rotation="0 0 0" 
                scale="0.15 0.15 0.15"
                type={1}
            />

            <ARCloud 
                position="0.312 -0.141 0.086" 
                rotation="0 0 0" 
                scale="0.18 0.18 0.18"
                type={1}
            />

            <ARCloud 
                position="0.245 0.144 0.036" 
                rotation="0 0 0" 
                scale="0.13 0.13 0.13"
                type={0}
            />

            <ARCloud 
                position="-0.328 0.104 0.037" 
                rotation="0 0 0" 
                scale="0.12 0.12 0.12"
                type={1}
            />

        </a-entity>
    )
}

export default Afiche3