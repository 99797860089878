import React, { useEffect, useRef, useState } from 'react'

// Componentes de aframe
import '../../../components/aframe-components/CustomGLBMaterial.js'
import '../../../components/aframe-components/SwapUvsComponents.js'
import '../../../components/aframe-components/MindarImageControllerComponent.js';
import '../../../components/aframe-components/HideNodesGLBComponent.js'

import LoadingIndicator from '../../../components/react-components/LoadingIndicator';
import ARCloud from '../AfichesAR/ARScenes/ARCloud';
import { anim_close, anim_spawn, anim_spawn_ar_container } from '../AfichesAR/AfichesAR';

const ShirtTororoi = () => {
    const sceneRef = useRef(null);
    const videoRef = useRef(null);
    const [arReady, setArReady] = useState(false);

    const repositionVideo = () => {
        const video = videoRef.current;
        const sceneEl = sceneRef.current;

        sceneEl.resize();
        if (video) {
            setTimeout(() => {
                video.style.left = 0;
                video.style.top = 0;
                video.style.objectFit = "cover";

                video.style.width = '100%';
                video.style.height = '100%';
            }, 100);

        }
    }

    useEffect(() => {

        const sceneEl = sceneRef.current;
        let arSystem = null;

        const handleRenderStart = () => {
            arSystem = sceneEl.systems["mindar-image-system"];
            arSystem.start();
        }

        const handleARReady = (event) => {
            // sceneEl.resize()

            videoRef.current = document.querySelector('video');
            //SOLUCIÓN TEMPORAL PARA VIDEO AR EN MÓVIL
            repositionVideo();
            setArReady(true);
        }

        let audio = new Audio(process.env.PUBLIC_URL + "models/Tororoi/Tororoi.mp3");
        audio.loop = true; // Reproducir en bucle
        const playAudio = () => {
            audio.play();
        }

        const stopAudio = () => {
            audio.pause(); // Pausa el audio
            audio.currentTime = 0; // Reinicia el audio al principio
        }

        // var audio = new Audio('audio_file.mp3');
        // audio.play();

        // detect target found
        sceneEl.addEventListener("targetFound", playAudio);
        // detect target lost
        sceneEl.addEventListener("targetLost", stopAudio);

        sceneEl.addEventListener('renderstart', handleRenderStart);
        sceneEl.addEventListener('arReady', handleARReady);
        window.addEventListener("resize", repositionVideo);

        return () => {
            if (arSystem) {
                arSystem.stop();
            }
            sceneEl.removeEventListener('renderstart', handleRenderStart);
            sceneEl.removeEventListener('arReady', handleARReady);
            sceneEl.removeEventListener('targetFound', playAudio);
            sceneEl.removeEventListener("targetLost", stopAudio);
            window.removeEventListener("resize", repositionVideo);
        };
    }, []);


    return (
        <div className='w-full h-full fixed'>
            {!arReady && <LoadingIndicator />}
            <a-scene ref={sceneRef} mindar-image="imageTargetSrc: ./ar_image_targets/target_tororoi.mind; autoStart: false; uiLoading: no; uiError: yes; uiScanning: yes; filterMinCF:0.0001; filterBeta: 0.01; warmupTolerance: 1; missTolerance: 3" color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights" xr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">

                <a-assets>
                    <img id="card" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/card.png" />
                    <a-asset-item 
                        id="TororoiModel" 
                        src={process.env.PUBLIC_URL + "/models/Tororoi/Tororoi.glb"}
                        ></a-asset-item>
                </a-assets>

                <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>
                <a-entity mindar-image-target="targetIndex: 0" mindar-image-controller="container:#contenedor-0">
                    <a-entity id="contenedor-0" position="0 0 0" rotation="0 0 0" animation__spawn={anim_spawn_ar_container}>
                        <a-gltf-model
                            position="-0.006 -0.401 -0.079"
                            rotation="0 33.011 0"
                            scale="1.1 1.1 1.1"
                            src="#TororoiModel"
                            visible="true"
                            animation-mixer="clip: Idle_tororoi; crossFadeDuration:0.2"
                            custom-glb-material={`
                                transparent: false; 
                                metalness: 0;
                                roughness: 1;
                                transparent: false;
                                opacity: 1;
                                swapUvs: true;
                            `}
                        ></a-gltf-model>

                        <a-gltf-model
                            position="-0.076 -0.531 0.719"
                            rotation="-3.431 -76.948 -0.785"
                            scale="15 15 15"
                            src={process.env.PUBLIC_URL + "/models/Wood.glb"}
                            visible="true"
                            hide-nodes="nodes: Group1, Group2, Group4, Group5, Group6, Group7, Group8, Group9, Group10"
                            custom-glb-material={`
                                transparent: false; 
                                metalness: 0;
                                roughness: 1;
                                opacity: 1;
                            `}
                        >
                        </a-gltf-model>

                        {/* <a-entity 
                            position="-0.006 -0.401 -0.079" 
                            rotation="0 33.011 0"  
                            sound="src: url(models/Tororoi/Tororoi.mp3); autoplay: true; loop: true;"
                        ></a-entity> */}



                        <ARCloud
                            position="-0.4 -0.137 0.092"
                            rotation="0 0 0"
                            scale="0.2 0.2 0.2"
                            type={0}
                        />

                        <ARCloud
                            position="-0.309 0.275 0.037"
                            rotation="0 0 0"
                            scale="0.15 0.15 0.15"
                            type={1}
                        />

                        <ARCloud
                            position="0.459 -0.173 0.068"
                            rotation="0 0 0"
                            scale="0.18 0.18 0.18"
                            type={1}
                        />

                        <ARCloud
                            position="0.449 0.459 0.011"
                            rotation="0 0 0"
                            scale="0.257 0.257 0.257"
                            type={0}
                        />
                    </a-entity>
                </a-entity>
            </a-scene>
        </div>
    )
}

export default ShirtTororoi