const AFRAME = window.AFRAME;

AFRAME.registerComponent('mindar-image-controller', {
    schema: {
        container: { type: 'selector', default: null },
    },

    init: function () {

        const el = this.el;
        const data = this.data;

        this.el.addEventListener('targetFound', event => {
            // console.log('encontrado')
            if(data.container){
                data.container.emit('spawnar')
            }
        });


        this.el.addEventListener('targetLost', event => {
            // console.log('perdido')
        });

    }
});