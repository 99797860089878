import React from 'react';

import { anim_spawn_ar_container } from '../AfichesAR';
import ARCloud from './ARCloud';

const Afiche1 = () => {
    return (
        <a-entity id="contenedor-0" position="0 0 0" rotation="0 0 0" animation__spawn={anim_spawn_ar_container}>
            {/* <a-plane
                id="image-1"
                src={process.env.PUBLIC_URL + "/ar_image_targets/Afiches_page_0001.jpg"}
                width="1" height="0.5641"
            ></a-plane>
            <a-sphere position="0 0 0" radius="0.1" color="#EF2D5E"></a-sphere> */}



            <a-gltf-model
                position="0.116 -0.208 0.021"
                rotation="0 0 0"
                scale="0.124 0.124 0.124"
                src={process.env.PUBLIC_URL + "/models/Currucutu/Currucutu.glb"}
                visible="true"
                animation-mixer="clip: root|Idle; crossFadeDuration:0.2"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    transparent: false;
                    opacity: 1;
                `}
            ></a-gltf-model>

            <a-gltf-model
                position="-0.126 -0.208 0.021"
                rotation="0 0 0"
                scale="0.124 0.124 0.124"
                src={process.env.PUBLIC_URL + "/models/Cotorra/Cotorra.glb"}
                visible="true"
                animation-mixer="clip: rig|iddle; crossFadeDuration:0.2"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    transparent: false;
                    opacity: 1;
                `}
                hide-nodes="nodes: cs_grp"
            ></a-gltf-model>

            <a-gltf-model
                position="-0.303 -0.338 0.591"
                rotation="0 -86.038 0.000"
                scale="13 13 13"
                src={process.env.PUBLIC_URL + "/models/Wood.glb"}
                visible="true"
                hide-nodes="nodes: Group1, Group2, Group4, Group5, Group6, Group7, Group8, Group9, Group10"
                custom-glb-material={`
                    transparent: false; 
                    metalness: 0;
                    roughness: 1;
                    opacity: 1;
                `}
            >
            </a-gltf-model>

            <ARCloud 
                position="-0.35 -0.071 0.092"
                rotation="0 0 0" 
                scale="0.2 0.2 0.2"
                type={0}
            />

            <ARCloud 
                position="-0.309 0.103 0.037" 
                rotation="0 0 0" 
                scale="0.15 0.15 0.15"
                type={1}
            />

            <ARCloud 
                position="0.373 -0.059 0.068" 
                rotation="0 0 0" 
                scale="0.18 0.18 0.18"
                type={1}
            />

            <ARCloud 
                position="-0.04 0.18 0.001" 
                rotation="0 0 0" 
                scale="0.13 0.13 0.13"
                type={0}
            />

        </a-entity>
    )
}

export default Afiche1