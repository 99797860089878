import React from 'react';
import RoundedCardOption from '../../../components/react-components/Cards/RoundedCardOption';
import BirdsData from '../BirdsData.json';

const BirdsOptions = [
    'tororoi',
    'bichofue',
    'petirrojo',
    'petirrojohembra',
    'carpinterolineado',
    'carpinteropequeno',
    'rupornis',
    'colibriamazilia',
    'colibricoliazul',
    'colibricolirraqueta',
    'nyctibius',
    'gallitoderoca',
    'mosqueronegro',
    'bucobobito',
    'montaneritopaisa',
    'cotorracabeciazul',
    'currucutu'
]

const Selfie = () => {
    return (
        <div className='w-full h-full md:p-20 pt-20'>
            <section className="text-gray-600 body-font">
                <div className="container flex flex-col px-5 pt-16 justify-center items-center">

                    <div className="w-full md:w-2/3 flex flex-col mb-8 items-center text-center font-bold">
                        <h1
                            className="
                            title-font 
                            sm:text-4xl text-3xl 
                            mb-4 
                            font-bold 
                            text-primary
                            bg-gradient-to-t from-secondary primary/200 to-primary bg-clip-text text-transparent"
                        >
                            SELFIE CON LAS AVES
                        </h1>

                        <h2>
                            Acompáñanos en esta experiencia de realidad aumentada que hemos preparado para tí en el marco de la COP16.
                        </h2>
                        <p className="my-4 leading-relaxed">
                            Por favor selecciona el ave con la que quieres tomarte una foto.
                        </p>
                    </div>
                </div>

                <div className="container flex flex-row flex-wrap justify-center items-center px-5 gap-10">
                    {
                        BirdsOptions.map((option, i) => {
                            
                            const bird_data = BirdsData.aves.find(e => e.id == option)
                            if(!bird_data) return null
                            
                            return (
                                <RoundedCardOption 
                                    key={i} 
                                    title={bird_data.nombre_comun}
                                    image={process.env.PUBLIC_URL + '/images/aves/' + bird_data.imagen}
                                    href={bird_data.id}
                                />
                            )
                        })
                    }
                    
                </div>
            </section>
        </div>
    )
}

export default Selfie;