import React, { useEffect, useRef, useState } from 'react'

const ARInstructionsModal = ({ open, image, text }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        if (open) {
            modalRef.current.showModal();
        }
        else {
            modalRef.current.close();
        }
    }, [open])

    return (
        <dialog className="modal" ref={modalRef}>
            <div className="flex flex-col items-center scanning">
                {/* <h3 className="text-primary font-semibold text-lg mb-3">{`¡Genial! Has elegido a "${birdData?.nombre_comun}"`} </h3> */}
                <img
                    src={image}//{process.env.PUBLIC_URL + '/images/aves/' + birdData?.foto}
                    alt="Aves de Colombia"
                    className='w-full max-h-96 h-80 object-contain rounded-lg opacity-50 animate-pulse'
                />
                <p className="m-4 max-w-lg text-center text-white bg-primary rounded-lg p-3 font-bold animate-none">{text}</p>

                <div className='inner'>
                    <div className='scanline'></div>
                </div>
            </div>
        </dialog>
    )
}

export default ARInstructionsModal