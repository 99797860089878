import React from 'react';
import './LoadingScreen.css';
import poster from '../../../assets/images/poster2_New.jpg'
import logo_animado from '../../../assets/images/logos/logo_animado.gif'
import posterbg from '../../../assets/images/poster-bg2.jpg'

const LoadingScreen = () => {
    return (
        <div className="loading-screen absolute">
            <div className="loading-background bg-black"  
                // style={{backgroundImage: `url(${poster})`}} 
            ></div>
            <div className="loading-content">
                <img src={logo_animado} alt="Cargando..." className='rounded-lg'/>
                {/* <p>Cargando...</p> */}
                {/* <p style={{margin:0, backgroundColor:"black"}}>PRÓXIMAMENTE</p> */}
            </div>
        </div>
    );
}

export default LoadingScreen;