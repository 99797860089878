import React, { useEffect, useState } from 'react'

const ARCloud = ({ position = "0 0 0", rotation = "0 0 0", scale = "1 1 1", type = 0, opacity = "0.9" }) => {

    const [randomDur, setRandomDur] = useState(random(1800, 2500));
    const [randomOpacity, setRandomOpacity] = useState(random(0.5, 1.0));

    function random(min, max) {
        return min + Math.random() * (max - min);
    }
    
    return (
        <a-entity
            id="nube-1"
            animation={`property: position; 
                        from: 0 0 0; 
                        to: 0 0.05 0; 
                        dir:alternate; 
                        dur: ${randomDur}; 
                        easing: easeInOutSine; 
                        loop: true
                    `}>
            <a-plane
                position={position}
                rotation={rotation}
                scale={scale}
                width="1" height="1"
                src={ process.env.PUBLIC_URL + "/images/ar/" + (type==0? "nube_1.png" : "nube_2.png")}
                material={`transparent:true; opacity:${randomOpacity};`}
            ></a-plane>
        </a-entity>
    )
}

export default ARCloud