import React from 'react'

const LoadingIndicator = () => {
    return (
        <div className='w-full h-full flex justify-center items-center absolute z-20 bg-base-200'>
            <span className="loading loading-dots loading-lg text-primary"></span>
            <p className='text-primary absolute bottom-4 m-5 text-center font-bold'>Para una mejor experiencia, conéctate a una red WiFi</p>
        </div>
    )
}

export default LoadingIndicator