import React from 'react';
import { useNavigate } from 'react-router-dom';

const AROptionCard = ({ title="Titulo", description="Descripcion", image='', href='#', disabled=false }) => {
    // let { panoId } = useParams();
    const navigate = useNavigate();

    return (
        <div className="card bg-base-100 sm:w-72 md:w-1/4 shadow-xl h-full min-h-full">
            <figure>
                <img
                    src={image || "https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.webp"}
                    alt="AROption" />
            </figure>
            <div className="card-body">
                <h2 className="card-title">{title}</h2>
                <p>{description}</p>
                <div className="card-actions justify-end mt-3">
                    
                    <button 
                        className="btn btn-outline btn-primary btn-sm"
                        onClick={() => navigate(href)}
                        disabled = {disabled}
                    >
                        {disabled ? 'Próximamente disponible' : 'Entrar'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AROptionCard;