const AFRAME = window.AFRAME;

AFRAME.registerComponent('swap-uvs', {
    init: function () {
        var el = this.el;
    
        el.addEventListener('model-loaded', function () {
            var model = el.getObject3D('mesh');
            if (model) {
            model.traverse(function (node) {
                
                if (node.isMesh && node.geometry.attributes.uv1) { // el segundo conjunto de UV existe
                
                node.geometry.setAttribute('uv', node.geometry.attributes.uv1);
                // node.material.transparent = false;
                // node.material.opacity = 1; 
                // node.material.depthWrite = true;
                // node.material.side = window.THREE.DoubleSide;
                // node.material.needsUpdate = true;
                
                }
            });
            }
        });
    }

});