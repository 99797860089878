import React from 'react';

// components
import SectionKarensHero from './SectionKarensHero';

const Karens = () => {
    return (
        <div className='w-full h-full md:p-20 pt-20'>
            <SectionKarensHero />
        </div>
    )
}

export default Karens;