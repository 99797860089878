import React, { useEffect, useRef, useState } from 'react'

const ARInfomationModal = ({ open, setOpen, image, title, description, visibleButton }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        if (open) {
            modalRef.current.showModal();
        }
        else {
            modalRef.current.close();
        }
    }, [open])

    const closeModal = () => {
        setOpen(false);
    }

    const openModal = () => {
        setOpen(true);
    }

    return (
        <div className='absolute w-full h-full z-10 flex align-bottom justify-center items-end p-5'>
            {visibleButton && <button className="btn btn-circle bg-white text-primary btn-lg"
                onClick={openModal}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className='h-10 w-10' viewBox="0 0 15 15"><path fill="#070062" d="M7.5 1C6.7 1 6 1.7 6 2.5S6.7 4 7.5 4S9 3.3 9 2.5S8.3 1 7.5 1M4 5v1s2 0 2 2v2c0 2-2 2-2 2v1h7v-1s-2 0-2-2V6c0-.5-.5-1-1-1z" /></svg>
            </button>
}
            <dialog className="modal" ref={modalRef}>
                <div className="modal-box flex flex-col">
                    <h4 className='w-full text-center text-primary font-bold text-xl'>{title}</h4>
                    
                    <div className='max-w-full w-full max-h-56 rounded-lg my-3 relative overflow-hidden' >
                        <img src={image} alt="AR Screenshot" className='h-full w-full max-h-56 object-cover blur-sm' />
                        <img src={image} alt="AR Screenshot" className='h-full w-full max-h-56 object-contain absolute top-0 right-0' />
                    </div>
                    
                    <p className='w-full text-center font-normal text-sm max-h-56 overflow-auto p-2'>{description}</p>
                    <div className="mt-4 flex gap-3 justify-center">
                        <button className="btn btn-error w-full" onClick={closeModal}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6'  viewBox="0 0 24 24"><path fill="#070062" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"/></svg> */}
                            Cerrar
                        </button>
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default ARInfomationModal;