import React from 'react';
import SectionDownloadsHero from './SectionDownloadHero';

const DownloadApp = () => {
    return (
        <div className='w-full h-full md:p-20 pt-20'>
            <SectionDownloadsHero />
        </div>
    )
}

export default DownloadApp;