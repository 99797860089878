import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//componentes React
import ARScreenshotView from '../ARScreenshotView.js';
import LoadingIndicator from '../../../components/react-components/LoadingIndicator.js';

// Componentes de aframe
import '../../../components/aframe-components/CustomGLBMaterial.js'
import '../../../components/aframe-components/HideNodesGLBComponent.js'

import BirdsData from '../BirdsData.json';

const ARSelfie = () => {

    const navigate = useNavigate();

    // Obtengo el id del ave de la url
    let { aveId } = useParams();

    const sceneRef = useRef(null);
    const videoRef = useRef(null);
    const [birdData, setBirdData] = useState(null);
    const [arReady, setArReady] = useState(false);


    const getBirdInformation = () => {
        if (!aveId) {
            navigate('/realidadaumentada/selfie')
            return
        }

        const bird_data = BirdsData.aves.find(e => e.id == aveId)
        if (!bird_data) {
            navigate('/realidadaumentada/selfie')
            return
        }
        setBirdData(bird_data)
    }

    const repositionVideo = () => {
        // const video = document.querySelector('video');
        console.log('video repos')
        const video = videoRef.current;
        const sceneEl = sceneRef.current;

        sceneEl.resize();
        if(video){
            setTimeout(() => {
                video.style.left = 0;
                video.style.top = 0;
                video.style.objectFit = "cover";

                video.style.width = '100%';
                video.style.height = '100%';

                // sceneRef.current.style.left = 0;
                // sceneRef.current.style.top = 0;
            }, 200);
            
        }
    }

    useEffect(() => {
        // Valido que exista información del ave para consultarla
        getBirdInformation()

        const sceneEl = sceneRef.current;
        let arSystem = null;

        const handleRenderStart = () => {
            arSystem = sceneEl.systems["mindar-face-system"];
            arSystem.start();
        }

        const handleARReady = (event) => {
            sceneEl.resize()

            videoRef.current =  document.querySelector('video');
            //SOLUCIÓN TEMPORAL PARA VIDEO AR EN MÓVIL
            repositionVideo();

            setArReady(true);
        }

        sceneEl.addEventListener('renderstart', handleRenderStart);
        sceneEl.addEventListener('arReady', handleARReady);
        window.addEventListener("resize", repositionVideo);

        return () => {
            if (arSystem) {
                arSystem.stop();
            }
            sceneEl.removeEventListener('renderstart', handleRenderStart);
            sceneEl.removeEventListener('arReady', handleARReady);
            window.removeEventListener("resize", repositionVideo);
        };
    }, []);

    return (
        <div id='selfie-ar-container' className='w-full h-full fixed'>
            { !arReady && <LoadingIndicator />}
            <ARScreenshotView/>
            
            <a-scene ref={sceneRef}
                embedded
                mindar-face={"autoStart: false; uiLoading: no;"}
                renderer="colorManagement: true, physicallyCorrectLights:true"
                xr-mode-ui="enabled: false"
                device-orientation-permission-ui="enabled: false"
            >
                {/* AFRAME ASSETS */}
                <a-assets>
                    <a-asset-item id="headModel"
                        src={process.env.PUBLIC_URL + '/models/headOccluder.glb'}>
                    </a-asset-item>

                    {/* <a-asset-item id="birdModel"
                        src={process.env.PUBLIC_URL + '/models/Bichofue.glb'}>
                    </a-asset-item> */}
                </a-assets>

                {/* CAMERA */}
                <a-camera
                    active="false"
                    position="0 0 0"
                    wasd-controls="enabled:false;"
                    look-controls="enabled:false;">
                </a-camera>

                {/* HEAD OCCLUDER */}
                <a-entity mindar-face-target="anchorIndex: 168">
                    <a-gltf-model mindar-face-occluder position="0 -0.3 0.15" rotation="0 0 0" scale="0.065 0.065 0.065"
                        src="#headModel"></a-gltf-model>
                </a-entity>

                {/* 3D BIRD MODEL */}
                {
                    birdData &&
                    <a-entity mindar-face-target="anchorIndex: 10">
                        <a-gltf-model
                            position={birdData.transform?.position}
                            rotation={birdData.transform?.rotation}
                            scale={birdData.transform?.scale}
                            src={process.env.PUBLIC_URL + "/models/" + birdData.modelo}
                            visible="true"
                            animation-mixer={`clip:${birdData.animaciones.selfie}; crossFadeDuration:0.2`}
                            hide-nodes={`nodes:${birdData.ocultar? birdData.ocultar: ""};`}
                            custom-glb-material={`
                                transparent: false; 
                                ${ birdData.material?.map? 
                                    `map: ${process.env.PUBLIC_URL + birdData.material.map};`: ""}
                                ${ birdData.material?.normalMap? 
                                    `normalMap: ${process.env.PUBLIC_URL + birdData.material.normalMap};`: ""}
                                ${ birdData.material?.metalnessMap? 
                                    `metalnessMap: ${process.env.PUBLIC_URL + birdData.material.metalnessMap};`: ""}
                                ${ birdData.material?.roughnessMap? 
                                    `roughnessMap: ${process.env.PUBLIC_URL + birdData.material.roughnessMap};`: ""}
                                ${ birdData.material?.metalness? `metalness: ${birdData.material.metalness};`: ""}
                                ${ birdData.material?.roughness? `roughness: ${birdData.material.roughness};`: ""}
                                ${ birdData.material?.transparent? `transparent: ${birdData.material.transparent};`: ""}
                                ${ birdData.material?.opacity? `opacity: ${birdData.material.opacity};`: ""}
                                ${ birdData.material?.alphaTest? `alphaTest: ${birdData.material.alphaTest};`: ""}
                                ${ birdData.material?.swapUvs? `swapUvs: ${birdData.material.swapUvs};`: ""}
                            `}>
                        </a-gltf-model>
                    </a-entity>
                }
            </a-scene>
        </div>
    )
}

export default ARSelfie;