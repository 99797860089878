import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import BirdsData from '../BirdsData.json';

const VoteModal = ({ open, setOpen, bird_id, getVotes }) => {

    const navigate = useNavigate();

    const [loadingVote, setLoadingVote] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [birdData, setBirdData] = useState(null);
    const [message, setMessage] = useState("Por favor confírmanos tu voto para guardar la información")

    const modalRef = useRef(null);

    const vote = async () => {
        setLoadingVote(true);

        try {
            // http://cvc.reinoalado.com:3200/api/votaciones
            // https://cvc.reinoalado.com/api/votaciones
            // https://backend-votaciones-cvc-nodejs.onrender.com/api/votaciones
            const response = await fetch("https://backend-votaciones-cvc-nodejs.onrender.com/api/votaciones", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json', // Asegúrate de incluir la cabecera Content-Type
                },
                body: JSON.stringify({
                    voto: bird_id
                }),
            });
            // console.log('response', response)

            // Verificar si la respuesta HTTP fue exitosa
            if (!response.ok) {
                setLoadingVote(false);
                throw new Error(`Error HTTP! status: ${response.status}`);
            }

            // Procesar la respuesta si todo fue bien
            const data = await response.json();
            console.log("Voto registrado exitosamente:", data);

            setLoadingVote(false);
            setConfirmed(true);
            getVotes();
        } catch (error) {
            // Manejar cualquier error que ocurra
            console.log("Hubo un error al intentar votar:", error.message);
        }

    }

    useEffect(() => {
        console.log('cargando info del ave');
    }, [])


    useEffect(() => {
        if (open) {
            modalRef.current.showModal();
            const bird_data = BirdsData.aves.find(e => e.id == bird_id)
            if (bird_data) {
                setBirdData(bird_data)
            }
            else {
                setBirdData({ error: true })
            }
        }
        else {
            modalRef.current.close();
        }
    }, [open])


    return (
        <dialog className="modal" ref={modalRef}>
            <div className="modal-box flex flex-col items-center">
                <h3 className="text-primary font-semibold text-lg mb-3">{`¡Genial! Has elegido a "${birdData?.nombre_comun}"`} </h3>
                <img
                    src={process.env.PUBLIC_URL + '/images/aves/' + birdData?.foto}
                    alt="Aves de cali"
                    className='w-full h-80 object-cover rounded-lg'
                />

                {

                }
                {bird_id != "tangaramulticolor" && <button className="btn btn-outline btn-info w-full mt-3" onClick={() => navigate(`selfie/${bird_id}`)} disabled={loadingVote}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-current' viewBox="0 0 24 24"><path d="M19 6h-1.586l-1-1c-.579-.579-1.595-1-2.414-1h-4c-.819 0-1.835.421-2.414 1l-1 1H5C3.346 6 2 7.346 2 9v8c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3V9c0-1.654-1.346-3-3-3m-7 10a3.5 3.5 0 1 1 .001-7.001A3.5 3.5 0 0 1 12 16m6-4.701a1.3 1.3 0 1 1 0-2.6a1.3 1.3 0 0 1 0 2.6" /></svg>
                    ¡Tomar una selfie!
                </button>}
                {!confirmed && <p className="my-3 text-center">Por favor confírmanos tu voto para guardar la información</p>}

                <div className="modal-action">
                    <div className="flex gap-3 justify-end">

                        {!confirmed && <button className="btn btn-outline btn-success" onClick={vote} disabled={loadingVote}>

                            {loadingVote ? <span className="loading loading-spinner"></span>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-current' viewBox="0 0 24 24"><path d="M21 8q.8 0 1.4.6T23 10v2q0 .175-.038.375t-.112.375l-3 7.05q-.225.5-.75.85T18 21h-8q-.825 0-1.412-.587T8 19V8.825q0-.4.163-.762t.437-.638l5.425-5.4q.375-.35.888-.425t.987.175t.688.7t.087.925L15.55 8zM4 21q-.825 0-1.412-.587T2 19v-9q0-.825.588-1.412T4 8t1.413.588T6 10v9q0 .825-.587 1.413T4 21" /></svg>
                            }
                            Confirmar
                        </button>}


                        <button className="btn btn-outline btn-error" onClick={() => setOpen(false)} disabled={loadingVote}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-current' viewBox="0 0 14 14"><path fillRule="evenodd" d="M6.545.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0-1.707-.707L.384 8.564a1 1 0 0 0-.22 1.09q.073.18.218.327l2.728 2.728a1 1 0 0 0 1.707-.707v-1.729h4.456a4.638 4.638 0 1 0 0-9.275z" clipRule="evenodd" /></svg>
                            Elegir otra ave
                        </button>
                    </div>
                </div>
            </div>
        </dialog>
    )
}

export default VoteModal