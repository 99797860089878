const AFRAME = window.AFRAME;

AFRAME.registerComponent('hide-nodes', {
    schema: {
        nodes: { type: 'array' },  // Lista de nombres de los nodos que quieres ocultar
    },

    init: function () {
        // Esperamos a que el modelo esté cargado
        this.el.addEventListener('model-loaded', () => {
            this.hideNodes();
        });
    },

    hideNodes: function () {
        const model = this.el.getObject3D('mesh');  // Obtenemos el modelo 3D

        if (!model) {
            console.warn('Modelo no encontrado.');
            return;
        }

        this.data.nodes.forEach((nodeName) => {
            const node = model.getObjectByName(nodeName);  // Buscamos el nodo por su nombre
            if (node) {
                node.visible = false;  // Ocultamos el nodo
            } else {
                console.warn(`Nodo ${nodeName} no encontrado.`);
            }
        });
    }
});