import React, { useEffect, useRef, useState } from 'react';

// Componentes de aframe
import '../../../components/aframe-components/CustomGLBMaterial.js'
import '../../../components/aframe-components/SwapUvsComponents.js'
import '../../../components/aframe-components/MindarImageControllerComponent.js';
import '../../../components/aframe-components/HideNodesGLBComponent.js'

// Escenas afiches
import Afiche1 from './ARScenes/Afiche1';
import Afiche2 from './ARScenes/Afiche2.js';
import Afiche3 from './ARScenes/Afiche3.js';

import LoadingIndicator from '../../../components/react-components/LoadingIndicator.js';

export const anim_spawn = "property: scale; from:0 0 0; to: 1 1 1; dur: 500; easing: easeInOutQuad;elasticity:1000; loop: false; dir: alternate; startEvents: spawn;"
export const anim_close = "property: scale; from:1 1 1; to: 0 0 0; dur: 500; easing: easeInOutQuad; loop: false; dir: alternate; startEvents: close;"
export const anim_spawn_ar_container = "property: scale; from:0 0 0; to: 1 1 1; dur: 1000; easing: easeOutElastic;elasticity:1000; loop: false; dir: normal; startEvents: spawnar;"

const AfichesAR = () => {
    const sceneRef = useRef(null);
    const videoRef = useRef(null);
    const [arReady, setArReady] = useState(false);

    const repositionVideo = () => {
        const video = videoRef.current;
        const sceneEl = sceneRef.current;

        sceneEl.resize();
        if(video){
            setTimeout(() => {
                video.style.left = 0;
                video.style.top = 0;
                video.style.objectFit = "cover";

                video.style.width = '100%';
                video.style.height = '100%';
            }, 100);
            
        }
    }

    useEffect(() => {

        const sceneEl = sceneRef.current;
        let arSystem = null;

        const handleRenderStart = () => {
            arSystem = sceneEl.systems["mindar-image-system"];
            arSystem.start();
        }

        const handleARReady = (event) => {
            // sceneEl.resize()

            videoRef.current =  document.querySelector('video');
            //SOLUCIÓN TEMPORAL PARA VIDEO AR EN MÓVIL
            repositionVideo();

            setArReady(true);
        }

        sceneEl.addEventListener('renderstart', handleRenderStart);
        sceneEl.addEventListener('arReady', handleARReady);
        window.addEventListener("resize", repositionVideo);

        return () => {
            if (arSystem) {
                arSystem.stop();
            }
            sceneEl.removeEventListener('renderstart', handleRenderStart);
            sceneEl.removeEventListener('arReady', handleARReady);
            window.removeEventListener("resize", repositionVideo);
        };
    }, []);
    
    
    return (
        <div className='w-full h-full fixed'>
            { !arReady && <LoadingIndicator />}
            <a-scene ref={sceneRef} mindar-image="imageTargetSrc: ./ar_image_targets/afiches_targets.mind; autoStart: false; uiLoading: no; uiError: no; uiScanning: yes; filterMinCF:0.0001; filterBeta: 0.01; warmupTolerance: 1; missTolerance: 3" color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights" xr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
                <a-assets>
                    {/* <img id="card" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/image-tracking/assets/card-example/card.png" /> */}
                    <a-asset-item id="avatarModel" src="/models/Tororoi/Tororoi.glb"></a-asset-item>
                </a-assets>

                {/* <a-gltf-model
                    position="0 0 0"
                    rotation="0 0 0"
                    scale="0.124 0.124 0.124"
                    src={process.env.PUBLIC_URL + "/models/BucoBobito/BucoBobito.glb"}
                    visible="true"
                    animation-mixer="clip: rig|iddle; crossFadeDuration:0.2"
                    swap-uvs
                    custom-glb-material={`
                        transparent: false; 
                        metalness: 0;
                        roughness: 1;
                        transparent: true;
                        opacity: 1;
                    `}
                    hide-nodes="nodes: cs_grp"
                ></a-gltf-model> */}

                <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>
                <a-entity mindar-image-target="targetIndex: 0" mindar-image-controller="container:#contenedor-0">
                    <Afiche2/>
                </a-entity>

                <a-entity mindar-image-target="targetIndex: 1" mindar-image-controller="container:#contenedor-0">
                    <Afiche1/>
                </a-entity>

                <a-entity mindar-image-target="targetIndex: 2" mindar-image-controller="container:#contenedor-0">
                    <Afiche3/>
                </a-entity>
            </a-scene>
        </div>
    )
}

export default AfichesAR