import React from 'react';
import { useNavigate } from 'react-router-dom';

const BirdCVCOption = ({ image, title="Nombre", subtitle="Nombre científico", href="#", onClick, votes= 0}) => {
    const navigate = useNavigate();

    return (
        <div 
            className="flex flex-col items-center text-center font-bold cursor-pointer" 
            onClick={() => onClick()}
        >
            <div className="avatar">
                <div className="w-48 rounded-full ring-4 ring-offset-2 ring-primary ring-offset-base-100">
                    <img src={image} />
                </div>
            </div>

            <div className="badge badge-neutral mt-4">{ title }</div>
            <div className="mt-1 font-light italic max-w-48 ">{ subtitle }</div>
            <div className="badge badge-primary badge-outline mt-4 font-medium max-w-48">{ `${votes} votos` }</div>
            
        </div>
    )
}

export default BirdCVCOption