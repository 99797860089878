import React from 'react';

// components
import AROptionCard from './AROptionCard';

const ARExperience = () => {
    return (
        <div className='w-full h-full md:p-20 pt-20'>
            <section className="text-gray-600 body-font">
                <div className="container flex flex-col px-5 pt-16 justify-center items-center">

                    <div className="w-full md:w-2/3 flex flex-col mb-8 items-center text-center font-bold">
                        <h1
                            className="
                            title-font 
                            uppercase
                            sm:text-4xl text-3xl 
                            mb-6
                            font-bold 
                            text-primary
                            bg-gradient-to-t from-secondary primary/200 to-primary bg-clip-text text-transparent"
                        >
                            Centro de experiencias de Realidad Aumentada del Reino Alado
                        </h1>

                        <h2>
                            Conoce las aves del Reino Alado e interactúa con ellas por medio de aplicaciones web de realidad aumentada
                        </h2>

                        {/* <p className="my-4 leading-relaxed">
                            Juntos logremos que en el “Reino Alado“ estén representadas todas las aves de Colombia y todas sus regiones.

                        </p>
                        <p className="mb-4 leading-relaxed">
                            Una red de aliados nos permitira crear una experiencia cautivadora, impactante e inolvidable para los 
                            visitantes al COP16 y toda la comunidad anfitriona.
                        </p> */}
                    </div>
                </div>

                <div className="container flex flex-row flex-wrap justify-center items-center px-5 gap-10">

                    <AROptionCard
                        title='Hologramas'
                        description='Selecciona el ave y visualiza en tu entorno un gemelo digital 3D en movimiento.'
                        image={process.env.PUBLIC_URL + "/images/ar/option_holograma.jpeg"}
                        href='foto'
                        disabled={true}
                    />

                    <AROptionCard
                        title='Selfie Alada'
                        description='Escanea en código QR y descubre las aves volando sobre tu cabeza, toma una fotografia y compártela con tus amigos.'
                        image={process.env.PUBLIC_URL + "/images/ar/option_selfie.jpeg"}
                        href='selfie'
                    />

                    <AROptionCard
                        title='De visita en la COP16'
                        description='Interactúa con los QR de los posters y experiencias que hacen parte del cubo interactivo.'
                        image={process.env.PUBLIC_URL + "/images/ar/option_afiche.jpeg"}
                        href='cubocop16'
                    />
                    <br />
                </div>
            </section>
        </div>
    )
}

export default ARExperience