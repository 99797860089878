import React from 'react';
import { useNavigate } from 'react-router-dom';

const RoundedCardOption = ({ image, title="Nombre", href="#" }) => {
    const navigate = useNavigate();

    return (
        <div 
            className="flex flex-col items-center text-center font-bold cursor-pointer" 
            onClick={() => navigate(href)}
        >
            <div className="avatar">
                <div className="w-24 rounded-full ring ring-offset-2 ring-primary ring-offset-base-100">
                    <img src={image} />
                </div>
            </div>

            <div className="h-12 flex items-center justify-center text-center bg-primary rounded-md p-1 leading-none mt-3 max-w-24 text-white">{ title }</div>
            
        </div>
    )
}

export default RoundedCardOption;