import React, { useState, useRef } from 'react';

// assets
import logo from '../../../assets/images/logos/logo_blanco.png'

// components
import NavbarOption from './NavbarOption'
import ContactForm from '../ContactForm/ContactForm'
import ContactButton from '../ContactForm/ContactButton'

const NAVIGATION_OPTIONS = [
    { name: 'El Reino Alado', href: '/elreinoalado' },
    { name: 'Noticias', href: '/noticias' },
    { name: 'Aliados', href: '/aliados' },
    { name: 'Participa', href: '/participa' },
    { name: 'Juega', href: '/app' },
    // { name: 'Contacto', href: '/contacto' },
]

const Header = () => {

    const dropdownRef = useRef(null)
    const inputDropdownRef = useRef(null)
    const onChangeOpenMenu = (e) => {
        dropdownRef.current.classList.toggle('dropdown-open');
        document.activeElement.blur();
    };

    const [contactDrawerOpen, setContactDrawerOpen] = useState(false)

    return (
        <header className="fixed top-0 body-font inset-x-0 z-50 bg-secondary/30 backdrop-blur-sm hover:bg-blend-soft-light">
            
            {/* DRAWER CONTACTO */}
            <ContactForm setOpen={setContactDrawerOpen} open={contactDrawerOpen} />
            {/* DRAWER CONTACTO */}

            <div className="container mx-auto flex flex-wrap md:p-4 md:px-8 p-4 flex-row items-center">
                <a className="flex title-font font-medium items-center" href='/'>
                    <img src={logo} width='50' height='50'/>
                    <span className="ml-3 text-xl text-primary">EL REINO ALADO</span>
                </a>

                {/* ESCRITORIO */}
                <nav className="hidden md:flex md:ml-auto flex-wrap items-center text-base justify-center">
                    {
                        NAVIGATION_OPTIONS.map((option, i) => {
                            return (
                                <NavbarOption key={i} title={option.name} path={option.href} />
                            )
                        }
                        )
                    }

                    {/* Boton Contactar */}
                    <ContactButton onClick={() => setContactDrawerOpen(!contactDrawerOpen)} />
                    {/* Boton Contactar */}

                </nav>
                {/* ESCRITORIO */}

                {/* MOVIL */}
                <div className="md:hidden ml-auto flex items-center text-white font-bold">
                    <div className="dropdown dropdown-end" ref={dropdownRef}>
                        <label className="btn btn-circle bg-white swap swap-rotate ">
                            <input type="checkbox" ref={inputDropdownRef} onChange={onChangeOpenMenu} />
                            <svg className="swap-off fill-current text-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32" height="32"
                                viewBox="0 0 512 512"
                            >
                                <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                            </svg>
                            <svg
                                className="swap-on fill-current text-error"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32" height="32"
                                viewBox="0 0 512 512"
                            >
                                <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
                            </svg>
                        </label>

                        <ul tabIndex="0" className="menu dropdown-content dropdown-open z-[1] px-2 py-6 shadow bg-primary rounded-box w-52 mt-5">
                            {
                                NAVIGATION_OPTIONS.map((option, i) => {
                                    return (
                                        <li key={i}>
                                            <NavbarOption title={option.name} path={option.href} />
                                        </li>
                                    )
                                }
                                )
                            }

                            <div className="divider divider-secondary"></div>

                            {/* Boton Contactar */}
                            <ContactButton onClick={() => { inputDropdownRef.current.click();setContactDrawerOpen(!contactDrawerOpen) }} />
                            {/* Boton Contactar */}
                        </ul>
                    </div>

                </div>
                {/* MOVIL */}


            </div>
        </header >
    )
}

export default Header;