window.AFRAME.registerComponent('custom-glb-material', {
    schema: {
      map: { type: 'asset' },
      normalMap: { type: 'asset' },
      metalnessMap: { type: 'asset' },
      roughnessMap: { type: 'asset' },
      metalness: { type: 'number', default: 0.0, min: 0.0, max: 1.0 },
      roughness: { type: 'number', default: 1.0, min: 0.0, max: 1.0 },
      transparent: { type: 'boolean', default: false }, // Habilita o deshabilita la transparencia.
      opacity: { type: 'number', default: 1.0, min: 0.0, max: 1.0 }, // Nivel de opacidad.
      swapUvs: { type: 'boolean', default: false }, // Habilita o deshabilita la transparencia.
      alphaTest: { type: 'number', default: 0.5, min: 0.0, max: 1.0 },
    },
  
    init: function () {
      this.applyMaterial();
    },
  
    update: function () {
      //this.applyMaterial();
    },
  
    applyMaterial: function () {
      const el = this.el;
      const data = this.data;
      console.log(data)

      const onLoadedTexture = (texture) => {
        texture.flipY=false
      }
  
      // Espera a que el modelo esté cargado
      el.addEventListener('model-loaded', () => {
        const model = el.getObject3D('mesh');
        if (model) {
          model.traverse((node) => {
            if (node.isMesh) {
              // Configura el material
              node.material = new window.THREE.MeshStandardMaterial({
                map: data.map ? new window.THREE.TextureLoader().load(data.map, onLoadedTexture) : node.material.map,
                normalMap: data.map ? new window.THREE.TextureLoader().load(data.map, onLoadedTexture) : node.material.normalMap,
                metalnessMap: data.map ? new window.THREE.TextureLoader().load(data.map, onLoadedTexture) : node.material.metalnessMap,
                roughnessMap: data.map ? new window.THREE.TextureLoader().load(data.map, onLoadedTexture) : node.material.roughnessMap,
                metalness: data.metalness,
                roughness: data.roughness,
                alphaTest: data.alphaTest, // Ajusta según sea necesario
                // depthWrite: false,
                // needsUpdate: true,
                transparent: data.transparent,
                opacity: data.opacity,
                side: window.THREE.DoubleSide
              });
  
              // Permite que el material use la transparencia si está habilitada
              // if (data.transparent) {
              //   node.material.alphaTest = data.alphaTest; // Ajusta según sea necesario
              //   node.material.depthWrite = false;
              // }

              // SWAPP UVS
              if ( data.swapUvs && node.geometry.attributes.uv1 ) { // el segundo conjunto de UV existe
                node.geometry.setAttribute('uv', node.geometry.attributes.uv1);
              }
            }

            
          });
        }
      });
    }
  });